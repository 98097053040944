<template>
  <section>
    <add-question-side-bar
      :form-id="formId"
      :user-roles="userRolesSelected"
      :form-type="formType"
      :question-type="questionTypeComp"
      :open="openAddSideBar"
      :title="sideBarTitle"
      :is-matching-question="isMatchingQuestion"
      :is-updating="isUpdating"
      :question-order="questionOrder"
      @toggleSideBar="toggleAddSideBar"
      @submit="createApplicationQuestion"
      @updateMatching="updateApplicationSet"
      @submitMatching="createApplicationMatchedPair"
    />
    <edit-question-side-bar
      :form-id="formId"
      :question-id="editQuestionId"
      :question-type="questionType"
      :open="openEditSideBar"
      :is-updating="isUpdating"
      @toggleSideBar="toggleEditSideBar"
      @update="updateApplicationQuestion"
      @updateMatching="updateApplicationSet"
    />
    <b-card class="box-shadow">
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <b-col sm="6">
              <question-skeleton-loader v-if="isFetching" />
              <b-row v-else>
                <b-col sm="8">
                  <h5 class="mt-2">
                    {{ roleAlias( userRoles.MENTOR, defaultProgram) }} - Application
                  </h5>
                </b-col>
                <b-col sm="4">
                  <add-question-button-lib
                    v-if="!applicationSet.is_locked"
                    :user-roles="getMentorRole"
                    :form-id="getMentorFormId"
                    @addQuestionType="showQuestionAdd"
                    @addLibQuestion="showLibQuestionAdd"
                  />
                </b-col>
              </b-row>
              <b-row v-if="!isLibrary">
                <b-col sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    ref="mentor-open-date"
                    :rules="{ required: true }"
                    name="Mentor Open Date"
                  >
                    <b-form-group label="Open Date">
                      <aom-date-picker
                        v-model="mentorOpenDate"
                        :state="errors.length > 0 ? false : null"
                        class="form-control"
                        :max="mentorDatePickerConfig.start.maxDate"
                      />
                    </b-form-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    ref="mentor-close-date"
                    :rules="{ required: true }"
                    name="Mentor Close Date"
                  >
                    <b-form-group label="Close Date">
                      <aom-date-picker
                        v-model="mentorCloseDate"
                        :state="errors.length > 0 ? false : null"
                        class="form-control"
                        :min="mentorDatePickerConfig.end.minDate"
                      />
                    </b-form-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
              </b-row>

              <draggable
                v-model="mentorQuestions"
                tag="div"
                class="drag-container"
              >
                <b-card
                  v-for="(question, index) in mentorQuestions"
                  :key="question.id"
                  no-body
                  class="mb-1 box-shadow"
                >
                  <b-card-header
                    header-tag="header"
                    class="p-1"
                    role="tab"
                    :style="{'background-color' : question.background}"
                  >
                    <b-col sm="1">
                      <feather-icon
                        class="movable"
                        icon="GridIcon"
                        size="15"
                      />
                    </b-col>

                    <b-col sm="6">
                      <span class="mr-50">{{ `${index + 1}` }}. </span>
                      <span>{{ question.translations[0].question_text }}</span>
                    </b-col>

                    <b-col
                      sm="4"
                      class="d-flex justify-content-center justify-content-xl-end"
                      :class="
                        viewMode === 'champion' && question.is_visible
                          ? 'align-items-center align-items-xl-end'
                          : 'align-items-center'
                      "
                    >
                      <div class="d-flex flex-column align-items-center">
                        <span
                          v-if="viewMode === 'champion' && question.is_visible"
                          class="font-small-1 mx-1"
                        >
                          VISIBLE
                        </span>
                        <span v-if="question.type_id === questionTypes.MATCHING && question.question_matched_pair && question.question_matched_pair.weight">
                          <matched-question-weight :question="question" />
                        </span>
                      </div>


                      <action-button
                        v-if="!applicationSet.is_locked"
                        size="sm"
                        class="d-none d-lg-block py-0 pr-1 pl-0"
                        variant="link"
                        @click="editQuestion(question)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="20"
                        />
                      </action-button>
                      <action-button
                        v-if="!applicationSet.is_locked"
                        size="sm"
                        class="d-none d-lg-block py-0 pr-1 pl-0"
                        variant="link"
                        @click="deleteQuestion(question)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          size="20"
                        />
                      </action-button>

                      <!-- Actions for mobile -->
                      <action-button
                        variant="outline-none"
                        class="d-block d-lg-none nav action-menu pr-0"
                      >
                        <b-nav-item-dropdown
                          class="btn-icon"
                        >
                          <template 
                            #button-content
                          >
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="20"
                            />                
                          </template>

                          <b-dropdown-item
                            v-if="!applicationSet.is_locked"
                            link-class="d-flex align-items-center"
                            @click="editQuestion(question)"
                          >
                            <feather-icon
                              size="16"
                              icon="EditIcon"
                              class="mr-50"
                            />
                            <span>Edit</span>
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-if="!applicationSet.is_locked"
                            link-class="d-flex align-items-center"
                            @click="deleteQuestion(question)"
                          >
                            <feather-icon
                              size="16"
                              icon="TrashIcon"
                              class="mr-50"
                            />
                            <span>Delete</span>
                          </b-dropdown-item>
                        </b-nav-item-dropdown>
                      </action-button>

                      <b-button
                        v-b-toggle="question.type_id === questionTypes.MATCHING ? `accordion-${question.question_matched_pair_id}` : `accordion-${question.id}`"
                        size="sm"
                        class="p-0"
                        variant="link"
                      >
                        <feather-icon
                          icon="ChevronUpIcon"
                          class="when-opened"
                          size="20"
                        />
                        <feather-icon
                          icon="ChevronDownIcon"
                          class="when-closed"
                          size="20"
                        />
                      </b-button>
                    </b-col>
                  </b-card-header>
                  <b-collapse
                    :id="question.type_id === questionTypes.MATCHING ? `accordion-${question.question_matched_pair_id}` : `accordion-${question.id}`"
                    visible
                    accordion="mentor-accordion"
                    role="tabpanel"
                  >
                    <b-card-body :class="{'overflow-scroll': question.type_id === questionTypes.SCALE}">
                      <div v-if="question.type_id === questionTypes.TEXT">
                        <label class="mt-1">Info text</label>
                        <b-form-group label-for="prefilled-answer">
                          <b-form-textarea
                            :value="question.translations[0].prefilled_answer"
                            disabled
                            placeholder="Prefilled answer"
                            maxlength="255"
                            rows="3"
                          />
                        </b-form-group>
                      </div>
                      <div v-if="question.type_id === questionTypes.CHOICE">
                        <div 
                          v-for="(choice, i) in question.choices" 
                          :key="i" 
                        >
                          <b-row>
                            <b-col md="12">
                              <b-row>
                                <b-col md="1">
                                  <b-form-radio 
                                    disabled
                                    name="question-options"
                                    class="mt-1"
                                  />
                                </b-col>
                                <b-col md="11">
                                  <b-form-input
                                    :value="question.choices[i].translations[0].choice_text"
                                    disabled
                                    class="mt-1"
                                    type="text"
                                    :placeholder="`Option ${i + 1}`"
                                  />
                                </b-col>
                              </b-row> 
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <div v-if="question.type_id === questionTypes.MATCHING">
                        <div 
                          v-for="(choice, i) in question.choices" 
                          :key="i" 
                        >
                          <b-row>
                            <b-col md="12">
                              <b-row>
                                <b-col md="1">
                                  <b-form-radio 
                                    disabled
                                    name="question-options"
                                    class="mt-1"
                                  />
                                </b-col>
                                <b-col md="11">
                                  <b-form-input
                                    :value="question.choices[i].translations[0].choice_text"
                                    disabled
                                    class="mt-1"
                                    type="text"
                                    :placeholder="`Option ${i + 1}`"
                                  />
                                </b-col>
                              </b-row> 
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <div v-if="question.type_id === questionTypes.SCALE">
                        <b-row
                          v-for="indexRow in (question.statements.length + 1)" 
                          :key="indexRow" 
                          class="mt-1 flex-nowrap"
                        >
                          <b-col 
                            v-for="indexCol in (question.choices.length + 2)"
                            :key="indexCol" 
                            class="col-sm-3"
                          >
                            <div
                              v-if="indexRow === 1 && indexCol === (question.choices.length + 2)"
                              class="minimal-width"
                            />

                            <div
                              v-if="indexRow === 1 && indexCol > 1 && indexCol < (question.choices.length + 2) "
                            >
                              <b-input-group 
                                :label-for="'option' + (indexCol-1) "
                              >
                                <b-form-textarea
                                  v-if="question.choices[indexCol - 2].translations[0]"
                                  disabled
                                  :value="question.choices[indexCol - 2].translations[0].choice_text"
                                  type="text"
                                  class="min-statement-width"
                                  :placeholder="'Option ' + (indexCol-1) "
                                  style="overflow:hidden"
                                  size="sm"
                                  no-resize
                                />
                              </b-input-group>
                            </div>
                            <div
                              v-else-if="indexRow > 1 && indexCol === 1"
                            >
                              <b-input-group 
                                :label-for="'statement' + (indexRow-1) "
                              >
                                <b-form-textarea
                                  v-if="question.statements[indexRow - 2].translations[0]"
                                  :value="question.statements[indexRow - 2].translations[0].question_text"
                                  disabled
                                  class="min-statement-width" 
                                  type="text"
                                  :placeholder="`Statement ${indexRow - 1}`"
                                  style="overflow:hidden"
                                  size="sm"
                                  no-resize
                                />
                              </b-input-group>
                            </div>
                            <div v-else-if="indexRow > 1 && indexCol > 1 && indexCol < (question.choices.length + 2)">
                              <b-form-radio
                                disabled
                                name="option-radio" 
                                class="ml-3"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </draggable>
            </b-col>
            <b-col sm="6">
              <question-skeleton-loader v-if="isFetching" />
              <b-row v-else>
                <b-col sm="8">
                  <h5 class="mt-2">
                    {{ roleAlias( userRoles.MENTEE, defaultProgram) }} - Application
                  </h5>
                </b-col>
                <b-col sm="4">
                  <add-question-button-lib
                    v-if="!applicationSet.is_locked"
                    :user-roles="getMenteeRole"
                    :form-id="getMenteeFormId"
                    @addQuestionType="showQuestionAdd"
                    @addLibQuestion="showLibQuestionAdd"
                  />
                </b-col>
              </b-row>
              <b-row v-if="!isLibrary">
                <b-col sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    ref="mentee-open-date"
                    :rules="{ required: true }"
                    name="Mentee Open Date"
                  >
                    <b-form-group label="Open Date">
                      <aom-date-picker
                        v-model="menteeOpenDate"
                        :state="errors.length > 0 ? false : null"
                        class="form-control"
                        :max="menteeDatePickerConfig.start.maxDate"
                      />
                    </b-form-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    ref="mentee-close-date"
                    :rules="{ required: true }"
                    name="Mentee Close Date"
                  >
                    <b-form-group label="Close Date">
                      <aom-date-picker
                        v-model="menteeCloseDate"
                        :state="errors.length > 0 ? false : null"
                        class="form-control"
                        :min="menteeDatePickerConfig.end.minDate"
                      />
                    </b-form-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
              </b-row>
              <draggable
                ref="dragContainer"
                v-model="menteeQuestions"
                tag="div"
                class="drag-container"
              >
                <b-card
                  v-for="(question, index) in menteeQuestions"
                  :key="question.id"
                  no-body
                  class="mb-1 box-shadow"
                >
                  <b-card-header
                    header-tag="header"
                    class="p-1"
                    role="tab"
                    :style="{'background-color' : question.background}"
                  >
                    <b-col sm="1">
                      <feather-icon
                        class="movable"
                        icon="GridIcon"
                        size="15"
                      />
                    </b-col>
                    <b-col sm="6">
                      <span class="mr-50">{{ `${index + 1}` }}. </span>
                      <span>{{ question.translations[0].question_text }}</span>
                    </b-col>
                    <b-col
                      sm="4"
                      class="d-flex justify-content-center justify-content-xl-end"
                      :class="
                        viewMode === 'champion' && question.is_visible
                          ? 'align-items-center align-items-xl-end'
                          : 'align-items-center'
                      "
                    >
                      <div class="d-flex flex-column align-items-center">
                        <span
                          v-if="viewMode === 'champion' && question.is_visible"
                          class="font-small-1 mx-1"
                        >
                          VISIBLE
                        </span>
                        <span v-if="question.type_id === questionTypes.MATCHING && question.question_matched_pair && question.question_matched_pair.weight">
                          <matched-question-weight :question="question" />
                        </span>
                      </div>

                      <action-button
                        v-if="!applicationSet.is_locked"
                        size="sm"
                        class="d-none d-lg-block py-0 pr-1 pl-0"
                        variant="link"
                        @click="editQuestion(question)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="20"
                        />
                      </action-button>
                      <action-button
                        v-if="!applicationSet.is_locked"
                        size="sm"
                        class="d-none d-lg-block py-0 pr-1 pl-0"
                        variant="link"
                        @click="deleteQuestion(question)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          size="20"
                        />
                      </action-button>
                      
                      <!-- Actions for mobile -->
                      <action-button
                        variant="outline-none"
                        class="d-block d-lg-none nav action-menu pr-0"
                      >
                        <b-nav-item-dropdown
                          class="btn-icon"
                        >
                          <template 
                            #button-content
                          >
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="20"
                            />                
                          </template>

                          <b-dropdown-item
                            v-if="!applicationSet.is_locked"
                            link-class="d-flex align-items-center"
                            @click="editQuestion(question)"
                          >
                            <feather-icon
                              size="16"
                              icon="EditIcon"
                              class="mr-50"
                            />
                            <span>Edit</span>
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-if="!applicationSet.is_locked"
                            link-class="d-flex align-items-center"
                            @click="deleteQuestion(question)"
                          >
                            <feather-icon
                              size="16"
                              icon="TrashIcon"
                              class="mr-50"
                            />
                            <span>Delete</span>
                          </b-dropdown-item>
                        </b-nav-item-dropdown>
                      </action-button>

                      <b-button
                        v-b-toggle="question.type_id === questionTypes.MATCHING ? `accordion-${question.question_matched_pair_id}` : `accordion-${question.id}`"
                        size="sm"
                        class="p-0"
                        variant="link"
                      >
                        <feather-icon
                          icon="ChevronUpIcon"
                          class="when-opened"
                          size="20"
                        />
                        <feather-icon
                          icon="ChevronDownIcon"
                          class="when-closed"
                          size="20"
                        />
                      </b-button>
                    </b-col>
                  </b-card-header>
                  <b-collapse
                    :id="question.type_id === questionTypes.MATCHING ? `accordion-${question.question_matched_pair_id}` : `accordion-${question.id}`"
                    visible
                    accordion="mentee-accordion"
                    role="tabpanel"
                  >
                    <b-card-body :class="{'overflow-scroll': question.type_id === questionTypes.SCALE}">
                      <div v-if="question.type_id === questionTypes.TEXT">
                        <label class="mt-1">Info text</label>
                        <b-form-group label-for="prefilled-answer">
                          <b-form-textarea
                            :value="question.translations[0].prefilled_answer"
                            disabled
                            placeholder="Prefilled answer"
                            maxlength="255"
                            rows="3"
                          />
                        </b-form-group>
                      </div>
                      <div v-if="question.type_id === questionTypes.CHOICE">
                        <div 
                          v-for="(choice, i) in question.choices" 
                          :key="i" 
                        >
                          <b-row>
                            <b-col md="12">
                              <b-row>
                                <b-col md="1">
                                  <b-form-radio 
                                    disabled
                                    name="question-options"
                                    class="mt-1"
                                  />
                                </b-col>
                                <b-col md="11">
                                  <b-form-input
                                    :value="question.choices[i].translations[0].choice_text"
                                    disabled
                                    class="mt-1"
                                    type="text"
                                    :placeholder="`Option ${i + 1}`"
                                  />
                                </b-col>
                              </b-row> 
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <div v-if="question.type_id === questionTypes.MATCHING">
                        <div 
                          v-for="(choice, i) in question.choices" 
                          :key="i" 
                        >
                          <b-row>
                            <b-col md="12">
                              <b-row>
                                <b-col md="1">
                                  <b-form-radio 
                                    disabled
                                    name="question-options"
                                    class="mt-1"
                                  />
                                </b-col>
                                <b-col md="11">
                                  <b-form-input
                                    :value="question.choices[i].translations[0].choice_text"
                                    disabled
                                    class="mt-1"
                                    type="text"
                                    :placeholder="`Option ${i + 1}`"
                                  />
                                </b-col>
                              </b-row> 
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <div v-if="question.type_id === questionTypes.STATEMENT">
                        statements
                      </div>
                      <div v-if="question.type_id === questionTypes.SCALE">
                        <b-row
                          v-for="indexRow in (question.statements.length + 1)" 
                          :key="indexRow" 
                          class="mt-1 flex-nowrap"
                        >
                          <b-col 
                            v-for="indexCol in (question.choices.length + 2)"
                            :key="indexCol" 
                            class="col-sm-3"
                          >
                            <div
                              v-if="indexRow === 1 && indexCol === (question.choices.length + 2)"
                              class="minimal-width"
                            />

                            <div
                              v-if="indexRow === 1 && indexCol > 1 && indexCol < (question.choices.length + 2) "
                            >
                              <b-input-group 
                                :label-for="'option' + (indexCol-1) "
                              >
                                <b-form-textarea
                                  v-if="question.choices[indexCol - 2].translations[0]"
                                  disabled
                                  :value="question.choices[indexCol - 2].translations[0].choice_text"
                                  type="text"
                                  class="min-statement-width"
                                  :placeholder="'Option ' + (indexCol-1) "
                                  style="overflow:hidden"
                                  size="sm"
                                  no-resize
                                />
                              </b-input-group>
                            </div>
                            <div
                              v-else-if="indexRow > 1 && indexCol === 1"
                            >
                              <b-input-group 
                                :label-for="'statement' + (indexRow-1) "
                              >
                                <b-form-textarea
                                  v-if="question.statements[indexRow - 2].translations[0]"
                                  v-model="question.statements[indexRow - 2].translations[0].question_text"
                                  disabled
                                  class="min-statement-width" 
                                  type="text"
                                  :placeholder="`Statement ${indexRow - 1}`"
                                  style="overflow:hidden"
                                  size="sm"
                                  no-resize
                                />
                              </b-input-group>
                            </div>
                            <div v-else-if="indexRow > 1 && indexCol > 1 && indexCol < (question.choices.length + 2)">
                              <b-form-radio
                                disabled
                                name="option-radio" 
                                class="ml-3"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </draggable>
            </b-col>
          </b-row>
  
      
          <b-row class="mt-5">
            <b-col
              sm="6"
              md="3"
              class="mb-1"
            >
              <b-button
                block
                type="reset"
                variant="outline-dark"
                @click="onCancel"
              >
                Cancel
              </b-button>
            </b-col>
            <b-col
              sm="6"
              md="3"
              class="mb-1"
            >
              <action-button
                block
                type="submit"
                variant="primary"
              >
                <b-spinner
                  v-if="isUpdating" 
                  small
                />
                <span
                  v-if="isUpdating"
                > Updating...</span>
                <span v-else>Submit</span>
              </action-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- <add-lib-question-to-application
      ref="addLibQuestionToApplication"
      :form-id="formId"
      :show-modal="showAddLibQuestionModal"
      @hiddenModal="hideAddLibQuestionModal"
      @libQuestionAdded="libQuestionAdded"
    /> -->
  </section>
</template>

<script>
import ActionButton from "@/views/components/common/ActionButton.vue";

import {
  BRow,
  BCol,
  BCard,
  BCollapse,
  BCardHeader,
  BButton,
  BSpinner,
  BForm,
  VBToggle,
  BCardBody,
  BFormTextarea,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BInputGroup,
  BNavItemDropdown,
  BDropdownItem
} from "bootstrap-vue";
import { mapGetters } from 'vuex';
import AddQuestionButtonLib from '@components/questions/AddQuestionButtonLib.vue';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { questionTypes } from '@models/questionTypes';
import AddQuestionSideBar from '@components/questions/AddQuestionSideBar.vue';
import EditQuestionSideBar from '@components/questions/EditQuestionSideBar.vue';
import draggable from 'vuedraggable';
import { mapMutations } from 'vuex';
import { userRoles, userRolesDisplay } from '@models/userRoles';
import { formTypes } from '@models/formTypes';
import { actions } from '@/models';
import formsService from '@/services/formsService';
import QuestionSkeletonLoader from '../../questions/QuestionSkeletonLoader.vue';
import questionsService from '@/services/questionsService';
import { pSBC } from "@/libs/utils";
import programsService from "@/services/programsService";
import MatchedQuestionWeight from "@/views/apps/champion/matches/MatchedQuestionWeight.vue";
import AomDatePicker from "@aom-core/AomDatePicker";
import useAppConfig from "@/@core/app-config/useAppConfig";
import { onUnmounted } from '@vue/composition-api';
import { roleAlias } from "@/@aom-core/utils/utils";

export default {
  name: 'ApplicationForm',
  components: {
    ActionButton,
    BCard,
    BRow,
    BCol,
    BCardBody,
    AddQuestionButtonLib,
    AddQuestionSideBar,
    BButton,
    BSpinner,
    BCardHeader,
    BCollapse,
    BFormTextarea,
    BForm,
    draggable,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormRadio,
    BFormInput,
    QuestionSkeletonLoader,
    BInputGroup,
    EditQuestionSideBar,
    MatchedQuestionWeight,
    AomDatePicker,
    BNavItemDropdown,
    BDropdownItem
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    viewMode: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      mentorOpenDate: undefined,
      mentorCloseDate: undefined,
      mentorDatePickerConfig: {
        start: {
          maxDate: undefined,
        },
        end: {
          minDate: undefined,
        }
      },
      menteeOpenDate: undefined,
      menteeCloseDate: undefined,
      menteeDatePickerConfig: {
        start: {
          maxDate: undefined,
        },
        end: {
          minDate: undefined,
        }
      },
      browserLocale: navigator.language,
      isUpdating: false,
      isFetching: false,
      mentorQuestions: [],
      menteeQuestions: [],
      applicationSet: {} ,
      applicationSetId: this.$route.params.application,
      questionTypeComp: undefined,
      userRolesSelected: [],
      openAddSideBar: false,
      questionTypes,
      openEditSideBar: false,
      editQuestionId: undefined,
      editQuestionType: undefined,
      sideBarTitle: this.title,
      isMatchingQuestion: false,
      questionType: undefined,
      formId: undefined,
      formType: undefined,
      showAddLibQuestionModal: false,
      questionOrder: 0,
      scrollingInterval: null
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram']),
    isLibrary() {
      return !this.applicationSet || !this.applicationSet.program_id;
    },
    getMentorRole() {
      return [{'id': userRoles.MENTOR,'name': userRolesDisplay[userRoles.MENTOR]}];
    },
    getMenteeRole() {
      return [{'id': userRoles.MENTEE,'name': userRolesDisplay[userRoles.MENTEE]}];
    },
    getFormType () {
      return {'id': formTypes.APPLICATION, 'name': 'Application' };
    },
    getMentorFormId () {
      const mentor = this.applicationSet.applications.filter(a => a.roles.every(r => r.id === userRoles.MENTOR));
      return mentor.map(m => m.id)[0];
    },
    getMenteeFormId () {
      const mentee = this.applicationSet.applications.filter(a => a.roles.every(r => r.id === userRoles.MENTEE));
      return mentee.map(m => m.id)[0];
    },
    cssVars() {
      // Max matches in a app set
      const primary = '#3c6f95';
      const secondary = '#cdde8799';
      return {
        "--aom-color-primary-light-10": pSBC(
          0.5,
          primary,
          false,
          true
        ),
        "--aom-color-primary-light-25": pSBC(
          0.6,
          secondary,
          false,
          true
        ),
        "--aom-color-primary-light-45": pSBC(
          0.7,
          primary,
          false,
          true
        ),
        "--aom-color-primary-light-65": pSBC(
          0.8,
          secondary,
          false,
          true
        ),
        "--aom-color-primary-light-90": pSBC(
          0.9,
          primary,
          false,
          true
        ),
      };
    }
  },
  watch: {
    mentorQuestions: {
      handler(n) {
        const newSort = n.map((q, i) => ({...q, question_order: i}));
        const mentorIndex = this.applicationSet.applications.findIndex(a => a.roles.every(role => role.id === userRoles.MENTOR));
        this.applicationSet.applications[mentorIndex] = {
          ...this.applicationSet.applications[mentorIndex],
          questions: newSort
        };
      },
      deep: true
    },
    menteeQuestions: {
      handler(n) {
        const newSort = n.map((q, i) => ({...q, question_order: i}));
        const menteeIndex = this.applicationSet.applications.findIndex(a => a.roles.every(role => role.id === userRoles.MENTEE));
        this.applicationSet.applications[menteeIndex] = {
          ...this.applicationSet.applications[menteeIndex],
          questions: newSort
        };
      },
      deep: true
    },
    mentorOpenDate: {
      handler(n) {
        this.setScheduledActionDate(userRoles.MENTOR, actions.SHOW, n);
        this.mentorDatePickerConfig.end.minDate = n;
      }
    },
    mentorCloseDate: {
      handler(n) {
        this.setScheduledActionDate(userRoles.MENTOR, actions.HIDE, n);
        this.mentorDatePickerConfig.start.maxDate = n;
      }
    },
    menteeOpenDate: {
      handler(n) {
        this.setScheduledActionDate(userRoles.MENTEE, actions.SHOW, n);
        this.menteeDatePickerConfig.end.minDate = n;

      }
    },
    menteeCloseDate: {
      handler(n) {
        this.setScheduledActionDate(userRoles.MENTEE, actions.HIDE, n);
        this.menteeDatePickerConfig.start.maxDate = n;
      }
    }
  },
  created() {
    this.fetchApplicationSet();
  },
  methods: {
    setScheduledActionDate(role, action, date) {
      if (!date) {
        return;
      }
      const idx = this.applicationSet.applications.findIndex(a => a.roles.every(r => r.id === role));
      if (idx === -1) {
        return;
      }
      if (!this.applicationSet.applications[idx].scheduled_actions) {
        this.applicationSet.applications[idx].scheduled_actions = [];
      }
      const saIdx = this.applicationSet.applications[idx].scheduled_actions.findIndex(sa => sa.action_id === action);
      if (saIdx === -1) {
        this.applicationSet.applications[idx].scheduled_actions.push({
          action_id: action,
          absolute_date: date
        });
      } else {
        this.applicationSet.applications[idx].scheduled_actions[saIdx] = {
          ...this.applicationSet.applications[idx].scheduled_actions[saIdx],
          absolute_date: date
        };
      }
    },
    onCancel () {
      if (this.viewMode === 'champion') {
        this.$router.push({ name: 'champion-program-application', params:{ id: this.$route.params.id, applicationId: this.applicationSetId }});
      } else {
        this.$router.push({ name: 'admin-application-list' });
      }
    },
    fetchUpdateService() {
      if (this.$route.meta.isProgramRoute) {
        const programId = this.$route.params.id;
        return formsService.updateProgramApplicationSet(programId, this.applicationSetId, this.applicationSet);
      }
      return formsService.updateApplicationSet(this.applicationSetId, this.applicationSet);
    },
    async onSubmit () {
      try {
        this.isUpdating = true;
        await this.fetchUpdateService();
        this.$toast(makeSuccessToast('Application updated successfully.'));
        this.clearForm();
        this.$router.back();
      } catch (e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    async fetchApplicationSet () {
      try {
        this.isFetching = true;
        let response = null;
        if (this.viewMode === 'champion') {
          response = await programsService.getApplicationSetByProgramId(this.$route.params.id, this.applicationSetId);
        } else {
          response = await formsService.getApplicationSet(this.applicationSetId);
        }
        const { data } = response;
        this.applicationSet = data;
        const {mentee, mentor} = this.mapApplicationData(data);
        this.menteeQuestions = mentee;
        this.mentorQuestions = mentor;
        const {mentorOpenDate, mentorCloseDate, menteeOpenDate, menteeCloseDate} = this.mapScheduledActionDates(data);
        this.mentorOpenDate = mentorOpenDate;
        this.mentorCloseDate = mentorCloseDate;
        this.menteeOpenDate = menteeOpenDate;
        this.menteeCloseDate = menteeCloseDate;
      } catch(e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isFetching = false;
      }
    },
    clearForm() {
      this.CLEAR_QUESTION();
      this.openAddSideBar = false;
      this.openEditSideBar = false;
      this.questionType = undefined;
      this.userRolesSelected = undefined;
      this.editQuestionId = undefined;
      this.isMatchingQuestion = false;
      this.questionTypeComp = undefined;
      this.sideBarTitle = '';
      this.formType = undefined;
      this.formId = undefined;
    },
    showQuestionAdd (qType) {
      // @TODO map this to a Map(type, component, title)
      const {type, roles, formId} = qType;
      this.questionOrder = roles[0]?.id === userRoles.MENTEE ? this.menteeQuestions.length : this.mentorQuestions.length;

      if (type.value === questionTypes.TEXT) {
        this.questionTypeComp = 'AddSingleTextBoxQuestionForm';
        this.sideBarTitle = 'Add New Question - Single Text Box';
        this.userRolesSelected = roles;
        this.openAddSideBar = true;
        this.formId = formId;
        this.formType = this.getFormType;
      }
      if (type.value === questionTypes.CHOICE) {
        this.questionTypeComp = 'AddMultipleChoiceQuestionForm';
        this.sideBarTitle = 'Add New Question - Multi Choice';
        this.userRolesSelected = roles;
        this.openAddSideBar = true;
        this.formId = formId;
        this.formType = this.getFormType;
      }
      if (type.value === questionTypes.STATEMENT) {
        this.questionTypeComp = 'AddRatingScalesQuestionForm';
        this.sideBarTitle = 'Add New Question - Rating Scale';
        this.userRolesSelected = roles;
        this.openAddSideBar = true;
        this.formId = formId;
        this.formType = this.getFormType;
      }
      if (type.value === questionTypes.MATCHING) {
        this.questionTypeComp = 'AddMultipleChoiceQuestionForm';
        this.sideBarTitle = 'Add New Question - Matching';
        this.userRolesSelected = roles;
        this.openAddSideBar = true;
        this.isMatchingQuestion = true;
        this.formType = this.getFormType;
        this.formId = formId;
      }
    },
    editQuestion(question) {
      const { id, type_id, question_matched_pair_id, form_id } = question;
      this.openEditSideBar = true;
      // eslint-disable-next-line camelcase
      this.formId = form_id;
      // eslint-disable-next-line camelcase
      if(type_id === questionTypes.MATCHING) {
        this.questionType = questionTypes.MATCHING;
        // eslint-disable-next-line camelcase
        this.editQuestionId = question_matched_pair_id;
      } else {
        // eslint-disable-next-line camelcase
        this.questionType = type_id;
        this.editQuestionId = id;
      }
    },
    toggleEditSideBar() {
      this.clearForm();
    },
    toggleAddSideBar() {
      this.clearForm();
    },
    async deleteQuestion(question) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this question ?', {
          title: 'Delete Question',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              const {id, form_id, question_matched_pair_id} = question;

              if (question.type_id === questionTypes.MATCHING) {
                if (this.$route.meta.isProgramRoute) {
                  await questionsService.deleteProgramFormMatchQuestion(this.$route.params.id, this.applicationSetId, question_matched_pair_id);
                } else {
                  await questionsService.deleteLibraryFormMatchQuestion(this.applicationSetId, question_matched_pair_id);
                }
              } else {
                if (this.$route.meta.isProgramRoute) {
                  await questionsService.deleteProgramFormQuestion(this.$route.params.id, form_id, id);
                } else {
                  await questionsService.deleteLibraryFormQuestion(form_id, id);
                }
              }
        
              this.$toast(makeSuccessToast('Question deleted successfully.'));
              this.fetchApplicationSet();
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });     
    },
    mapApplicationData(data) {
      // Maps matching questions with the same background color
      const menteeQ = data.applications.filter(a => a.roles.every(role => role.id === userRoles.MENTEE));
      const mentorQ = data.applications.filter(a => a.roles.every(role => role.id === userRoles.MENTOR));
      const backColors = Object.values(this.cssVars);
      const mappedMatchingQ = menteeQ[0].questions.filter(mq => mq.type_id === questionTypes.MATCHING);
      const filteredMatches = mappedMatchingQ.map((q, index) => {
        if (q.type_id === questionTypes.MATCHING) {
          const backGroundColorObj = {background: backColors[index] };
          return Object.assign({}, q, backGroundColorObj );
        }
        return q;
      });
      const menteeQuestions = menteeQ[0].questions.map(q => {
        if(q.type_id === questionTypes.MATCHING) {
          return filteredMatches.find(f => f.id === q.id);
        }
        return q;
      });
      const mentorQuestions = mentorQ[0].questions.map(mq => {
        if(mq.type_id === questionTypes.MATCHING) {
          const findMatch = filteredMatches.find(f => f.question_matched_pair_id === mq.question_matched_pair_id);
          if(findMatch) {
            const backGroundColorObj = {background: findMatch.background };
            return Object.assign({}, mq, backGroundColorObj); 
          }
          return mq;
        } 
        return mq;
      });
      return {'mentee' : menteeQuestions, 'mentor': mentorQuestions };
    },
    mapScheduledActionDates(data) {
      const dates = {
        mentorOpenDate: undefined,
        mentorCloseDate: undefined,
        menteeOpenDate: undefined,
        menteeCloseDate: undefined
      };
      const menteeForm = data.applications.filter(a => a.roles.every(role => role.id === userRoles.MENTEE))[0];
      const mentorForm = data.applications.filter(a => a.roles.every(role => role.id === userRoles.MENTOR))[0];

      if (menteeForm && menteeForm.scheduled_actions) {
        const open = menteeForm.scheduled_actions.filter(sa => sa.action_id === actions.SHOW)[0];
        const close = menteeForm.scheduled_actions.filter(sa => sa.action_id === actions.HIDE)[0];
        if (open) {
          dates.menteeOpenDate = open.absolute_date;
        }
        if (close) {
          dates.menteeCloseDate = close.absolute_date;
        }
      }

      if (mentorForm && mentorForm.scheduled_actions) {
        const open = mentorForm.scheduled_actions.filter(sa => sa.action_id === actions.SHOW)[0];
        const close = mentorForm.scheduled_actions.filter(sa => sa.action_id === actions.HIDE)[0];
        if (open) {
          dates.mentorOpenDate = open.absolute_date;
        }
        if (close) {
          dates.mentorCloseDate = close.absolute_date;
        }
      }

      return dates;
    },
    createQuestion(formId, question) {
      if (this.$route.meta.isProgramRoute) {
        return questionsService.createProgramFormQuestion(this.$route.params.id, formId, question);
      }
      return questionsService.createLibraryFormQuestion(formId, question);
    },
    async createApplicationQuestion(data) {
      const {formId, question } = data;
      try {
        this.isUpdating = true;
        await this.createQuestion(formId, question);
        this.fetchApplicationSet();
        this.$toast(makeSuccessToast('Question created successfully.'));
        this.clearForm();
      } catch(e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    updateQuestion(formId, questionId, question) {
      if (this.$route.meta.isProgramRoute) {
        return questionsService.updateProgramFormQuestion(this.$route.params.id, formId, questionId, question);
      }

      return questionsService.updateLibraryFormQuestion(formId, questionId, question);
    },
    async updateApplicationQuestion(data) {
      const { formId, question } = data;
      const { id } = question;
      try {
        this.isUpdating = true;
        await this.updateQuestion(formId, id, question);
        this.fetchApplicationSet();
        this.$toast(makeSuccessToast('Question updated successfully.'));
        this.clearForm();
      } catch(e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },

    createMatchedPair(question) {
      if (this.$route.meta.isProgramRoute) {
        return questionsService.postCreateProgramMatchedPair(this.$route.params.id, this.applicationSetId, question);
      }
      if(this.applicationSetId) {
        return formsService.createLibraryApplicationSetMatchedPair(this.applicationSetId, question);
      }
      return questionsService.postCreateMatchedPair(question);
    },
    async createApplicationMatchedPair(question) {
      try {
        this.isUpdating = true;
        await this.createMatchedPair(question);
        this.reloadList = true;
        this.fetchApplicationSet();
        this.$toast(makeSuccessToast('Question created successfully.'));
        this.clearForm();
      } catch(e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },

    updateMatchQuestionPair(questionMatchedPairId, weight, questions) {
      if (this.$route.meta.isProgramRoute) {
        return questionsService.patchProgramMatchQuestion(this.$route.params.id, this.applicationSetId, questionMatchedPairId, {weight, questions});
      } else {
        return questionsService.patchMatchQuestion(questionMatchedPairId, {weight, questions});
      }
    },

    async updateApplicationSet(data) {
      const {question_matched_pair_id } = data.questions[0]; 
      const { weight , questions } = data; 
      try {
        this.isUpdating = true;
        await this.updateMatchQuestionPair(question_matched_pair_id, weight, questions);
        this.reloadList = true;
        this.fetchApplicationSet();
        this.$toast(makeSuccessToast('Question updated successfully.'));
        this.clearForm();
      } catch(e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    showLibQuestionAdd (data) {
      this.showAddLibQuestionModal = true;
      const { formId } = data;
      this.formId = formId;
    },
    hideAddLibQuestionModal() {
      this.showAddLibQuestionModal = false;
      this.formId = 0;
    },
    libQuestionAdded() {
      this.fetchApplicationSet();
    },
    ...mapMutations('questions',
      ['CLEAR_QUESTION']
    ),
  },
  setup() {
    const {
      navbarType,
    } = useAppConfig();
    navbarType.value = 'static';
    onUnmounted(() => {
      navbarType.value = 'floating';
    });
    return {
      navbarType,
      roleAlias,
      userRoles
    };
  }
};
</script>

<style lang="scss" scoped>
  $sidebar-header-text-color: #808080;
  .b-sidebar > .b-sidebar-header{
    flex-direction: row-reverse;
    background: #f3f3f3;
    color: $sidebar-header-text-color;

    #add-questions-sidebar___title__ {
      font-size: .8em;
      flex: 2;
    }
  }
  
  .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
    .matching{
      background-color: #a6e490 !important;
      color: #348818;
    }
    .box-shadow {
      box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
    }
    .overflow-scroll {
      overflow: scroll;
    }
    .movable {
      cursor: move;
    }
</style>
<style lang="scss">
  .nav .dropdown-toggle:not(.active)::after {
    display: none;
    background-image: none !important;
  }
</style>