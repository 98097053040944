<template>
  <!-- Header section -->
  <section>
    <b-row class="mt-2">
      <b-col
        md="12"
      >
        <b-form-group label-for="visible-to-match">
          <b-form-checkbox
            v-if="!isSurveyForm"
            v-model="isVisibleLocal"
            :name="`visible-to-match-${index}`"
            switch
            inline
          >
            <h5>Visible to Match</h5>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
      >
        <b-form-group
          v-if="!isMatchingQuestion" 
          label-for="is-required"
        >
          <b-form-checkbox
            :id="`is-required-${index}`"
            v-model="isRequiredLocal"
            :name="`required-${index}`"
            switch
            inline
          >
            <h5>Required</h5>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div class="d-flex">
          <b-form-group
            v-if="!isMatchingQuestion && isShowProfileSummary"
            label-for="show-in-profile"
          >
            <b-form-checkbox
              :id="`show-in-profile-${index}`"
              v-model="isSummarisableLocal"
              :name="`show-in-profile-${index}`"
              switch
              inline
            >
              <h5>Show in Profile Summary</h5>
            </b-form-checkbox>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import { formTypes } from  '@models';

export default {
  name: 'QuestionToolBar',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    isVisible: {
      type: [Boolean, Number],
      default: 0
    },
    isRequired: {
      type: [Boolean, Number],
      default: 1
    },
    isSummarisable: {
      type: [Boolean, Number],
      default: 0
    },
    isLocked: {
      type: [Boolean, Number],
      default: 0
    },
    isRepeatable: {
      type: [Boolean, Number],
      default: 0
    },
    localeId: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    isMatchingQuestion: {
      type: Boolean
    },
    formType: {
      type: Object,
      default: () => {}
    }
  },
  data(vm) {
    return {
      isVisibleLocal: vm.isVisible,
      isRequiredLocal: vm.isRequired,
      isSummarisableLocal: vm.isSummarisable,
      isLockedLocal: vm.isLocked,
      isRepeatableLocal: vm.isRepeatable
    };
  },
  computed: {
    ...mapGetters('questions', ['questionCommon']),
    isQuestionRepeatable() {
      return this.isRepeatableLocal ? 'secondary' : 'flat-primary';
    },
    isShowProfileSummary() {
      return this.questionCommon.target_form_type_id === formTypes.APPLICATION;
    },
    isSurveyForm () {
      return this.formType && (this.formType.id === formTypes.SURVEY);
    }
  },
  watch: {
    isLocked() {
      this.isLockedLocal = Boolean(this.isLocked);
    },
    isRequired() {
      this.isRequiredLocal = Boolean(this.isRequired);
    },
    isVisible() {
      this.isVisibleLocal = Boolean(this.isVisible);
    },
    isRequiredLocal() {
      this.$emit('updateIsRequired', this.isRequiredLocal);
    },
    isSummarisable() {
      this.isSummarisableLocal = Boolean(this.isSummarisable);
    },
    isSummarisableLocal() {
      this.$emit('updateIsSummarisable', this.isSummarisableLocal);
    },
    isVisibleLocal() {
      this.$emit('updateIsVisible', this.isVisibleLocal);
    },
    isLockedLocal () {
      this.$emit('updateIsLocked', this.isLockedLocal);
    }
  },
  methods: {
    updateRepeatable () {
      this.isRepeatableLocal = !this.isRepeatableLocal;
      this.$emit('updateIsRepeatable', this.isRepeatableLocal);
    },
    updateLocked () {
      this.isLockedLocal = !this.isLockedLocal;
    }
  }
};
</script>

